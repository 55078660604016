import React from 'react';
import kanbanImage from '../../assets/kanban_filtered.jpeg';
import { ChartNoAxesCombined, CircleGauge } from 'lucide-react'

export const FeatureSection = () => {
  return (
    <section>
      <div className="w-full flex justify-center overflow-hidden bg-gray-100 mx-auto">
        <div className="w-full flex flex-col-reverse xl:flex-row justify-between my-20 gap-10 xl:gap-20 px-4 xl:px-10">
          {/* Image - Now second in mobile order due to flex-col-reverse */}
          <div className="w-full xl:w-[50%] flex items-center rounded-2xl border-8 border-gray-50 justify-center shadow-xl">
            <img 
              src={kanbanImage} 
              alt="Kanban Board" 
              className="w-full object-left rounded-2xl object-cover"
            />
          </div>

          {/* Content - Now first in mobile order due to flex-col-reverse */}
          <div className="w-full xl:w-[50%] h-full flex flex-col justify-center">
            <div>
              <div className="w-[100%] flex xs: justify-center xl:justify-start">
                <div className="inline-flex items-center bg-gray-50 p-1 py-1 rounded-md mb-10 whitespace-nowrap">
                  <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
                  <div className="text-blue-500 text-sm font-medium font-mono px-1">FEATURES</div>
                  <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
                </div>
              </div>
              <div className="flex flex-col items-center xl:items-start text-center xl:text-left">
                <h1 className="text-5xl font-bold mb-4 whitespace-normal xl:whitespace-nowrap">
                  <span className="text-blue-500">Intelligent</span> issue management
                </h1>
                <p className="text-gray-700 w-full max-w-[100%] mt-1 mb-10">
                  Leverage highly searchable data about issues and the conversations your customers/team are having about them.
                </p>
                
                {/* Feature Cards */}
                <div className="flex flex-col md:flex-row justify-center xl:justify-start w-full gap-10 xl:gap-20">
                  <div className="w-full md:w-[275px] h-auto md:h-[275px] py-5 px-5 leading-relaxed flex-col item-center text-center bg-white rounded-3xl border-gray-100 border-2 shadow-md hover:shadow-xl">                
                    <div className="flex justify-center mb-3">
                      <div className="rounded-3xl bg-blue-500 bg-opacity-25 p-2">
                        <CircleGauge size={48} />                
                      </div>
                    </div>
                    <h1 className="font-bold mb-3 xl:text-2xl">Issue Tracking</h1>
                    <p className="text-lg text-gray-600">                    
                      Automatically compile relevant threads, subtasks, and response metrics as issues evolve.              
                    </p>
                  </div>

                  <div className="w-full md:w-[275px] h-auto md:h-[275px] py-5 px-5 leading-relaxed flex-col item-center text-center bg-white rounded-3xl border-gray-100 border-2 shadow-md hover:shadow-xl">                
                    <div className="flex justify-center mb-3">
                      <div className="rounded-3xl bg-blue-500 bg-opacity-25 p-2">
                        <ChartNoAxesCombined size={48} />                
                      </div>
                    </div>
                    <h1 className="font-bold mb-3 text-2xl">Issue Trends</h1>
                    <p className="text-lg text-gray-600">                    
                      Surface trends across issues to determine your customers' most pressing requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};