// src/App.jsx
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { HeroSection } from './components/sections/HeroSection';
import { FeatureSection } from './components/sections/FeatureSection';
import { IntegrationsSection } from './components/sections/IntegrationsSection';
import { CalendarSection } from './components/sections/CalendarSection';

const App = () => {

  useEffect(() => {
    AOS.init({
      duration: 800, // animation duration in milliseconds
      once: true, // only animate once
    });
  }, []);

  return (
    <div className="flex flex-col">
      <Header />
      <main className="flex flex-col overflow-hidden">
        <div data-aos="fade-right">
          <HeroSection />
        </div>
        <div data-aos="fade-up">
          <IntegrationsSection />
        </div>
        <div data-aos="fade-left">
          <FeatureSection />
        </div>
        <div data-aos="fade-up">
          <CalendarSection id="CalendarSection"/>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default App;
